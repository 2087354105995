import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/pedir-credencial',
    name: 'Pedir Credencial',
    component: () => import('../views/AskCard.vue')
  },
  {
    path: '/validar-credencial',
    name: 'Validar Credencial',
    component: () => import('../views/ValidationCard.vue')
  },
  {
    path: '/preguntas-frecuentes',
    name: 'Preguntas Frecuentes',
    component: () => import('../views/Faq.vue')
  },
  {
    path: '/producto/:productId/:variationId/:name',
    name: 'Producto',
    component: () => import('../views/ProductView.vue')
  },
  {
    path: '/carrito',
    name: 'Carrito',
    component: () => import('../views/Cart.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/mi-cuenta',
    name: 'Account',
    component: () => import('../views/MyAccount.vue')
  },
  {
    path: '/pedidos',
    name: 'Pedidos',
    component: () => import('../views/Orders.vue')
  },
  {
    path: '/pedidos/:id',
    name: 'Detalle Pedido',
    component: () => import('../views/OrderDetails.vue')
  },
  {
    path: '/mis-datos',
    name: 'Mis Datos',
    component: () => import('../views/UserData.vue')
  },
  /*{
    path: '/turnos',
    name: 'Turnos',
    component: () => import('../views/AppointmentForm.vue')
  },
  {
    path: '/mis-turnos',
    name: 'Mis Turnos',
    component: () => import('../views/MyAppointments.vue')
  },*/
  {
    path: '/tienda',
    name: 'Tienda',
    component: () => import('../views/Store.vue')
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import('../views/Blog.vue')
  },
  {
    path: '/post/:id',
    name: 'BlogPost',
    component: () => import('../views/BlogPost.vue')
  },
  /*{
    path: '/certificado-reprocann',
    name: 'Obtener Certificado',
    component: () => import('../views/Doctors.vue')
  },*/
  {
    path: '/sobre-nosotros',
    name: 'Sobre Nosotros',
    component: () => import('../views/AboutUs.vue')
  },
  /*{
    path: '/asesoramiento-clubes',
    name: 'Asesoramiento clubes',
    component: () => import('../views/Consultancy.vue')
  },*/
  {
    path: '/terminos',
    name: 'Términos y condiciones',
    component: () => import('../views/Terms.vue')
  },
  {
    path: "/:pathMatch(.*)*",
    name: '404',
    component: function() {
        return import ('../views/404.vue')
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
