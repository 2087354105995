
import { defineComponent } from "vue";
import Button from "@/components/Button.vue";
import Toast from "@/components/Toast.vue";
import Swiper, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import utilities from "../utilities";

interface Product {
  id: number;
  name: string;
  selectedVariation: ProductVariation;
  variations: ProductVariation[];
  no_stock?: boolean;
}

interface ProductVariation {
  id: number;
  price: number;
  discount: number | null;
  status: string;
  color: string;
  stock?: number;
  image: {
    data: {
      thumbnails: Array<{
        url: string;
      }>;
    };
  };
  hover_image?: {
    data: {
      thumbnails: Array<{
        url: string;
      }>;
    };
  };
}

interface Banner {
  id: number;
  title: string;
  link: string;
  image?: {
    data: {
      thumbnails: Array<{
        url: string;
      }>;
    };
  };
}

interface FeaturedPost {
  id: string;
  title: string;
  subtitle: string;
  author: string;
  date: string;
  image: string;
}

export default defineComponent({
  computed: {
    limitedPosts(): FeaturedPost[] {
      return this.featuredPosts.slice(0, 3);
    }
  },
  name: "Home",
  components: {
    Button,
    Toast,
  },
  data() {
    return {
      basePrice: 0,
      banners: [] as Banner[],
      products: [] as Product[],
      isHover: {} as Record<number, boolean>,
      validated: false,
      featuredPosts: [] as FeaturedPost[],
      loading: {
        config: true,
        posts: true
      },
      featuredProductsSwiper: null as Swiper | null,
    };
  },
  async created() {
    // Start data fetching as early as possible
    this.fetchData();
  },
  mounted() {
    // Initialize Swipers when component is mounted
    this.initializeSwipers();
  },
  methods: {
    async fetchData() {
      try {
        // Fetch config and posts in parallel
        await Promise.all([
          this.fetchConfig(),
          this.fetchFeaturedPosts()
        ]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    async fetchConfig() {
      try {
        this.loading.config = true;
        const configData = await utilities.getItems("config/1?fields=*.*.*.*");

        this.basePrice = configData.credential_price;
        this.banners = configData.banners;
        this.products = configData.featured_products;

        this.products.forEach((product, index) => {
          this.products[index].selectedVariation = product.variations[0];
        });

        this.validated = (await utilities.isValidated()).isValid;
        this.loading.config = false;
      } catch (error) {
        console.error('Error fetching config:', error);
        this.loading.config = false;
      }
    },
    formatPrice(price: number): string {
      return price?.toLocaleString('es-AR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }) || '0.00';
    },
    setVariation(productIndex: number, variationIndex: number): void {
      if (this.products[productIndex].variations[variationIndex].status === "published") {
        this.products[productIndex].selectedVariation = this.products[productIndex].variations[variationIndex];
      }
    },
    calculateFinalPrice(product: Product): number {
      if (!product?.selectedVariation) return 0;

      const price = product.selectedVariation.price;
      const discount = product.selectedVariation.discount || 0;
      const verifiedDiscount = this.validated && this.$store.state.flags.descuentoVerificado ? 5 : 0;

      const totalDiscount = discount + (this.validated ? verifiedDiscount : 0);
      return price - (totalDiscount / 100 * price);
    },
    showDiscountedPrice(product: Product): boolean {
      return (
        (product?.selectedVariation?.discount !== null && product?.selectedVariation?.discount > 0) ||
        (this.validated && this.$store.state.flags.descuentoVerificado)
      );
    },
    getDiscount(product: Product): number | null {
      if (!product?.selectedVariation) return null;

      const discount = product.selectedVariation.discount || 0;
      const verifiedDiscount = this.validated && this.$store.state.flags.descuentoVerificado ? 5 : 0;

      const totalDiscount = discount + (this.validated ? verifiedDiscount : 0);
      return totalDiscount > 0 ? totalDiscount : null;
    },
    async addProduct(product: Product): Promise<void> {
      const toast = this.$refs.toast as InstanceType<typeof Toast>;

      try {
        if (product.no_stock) {
          toast.show("Lo sentimos, este producto no está disponible", "");
          return;
        }

        if (
          product.selectedVariation?.status === "published" 
          || (product.selectedVariation?.stock != null && product.selectedVariation?.stock > 0)
        ) {
          this.$store.commit("addProduct", product);
          toast.show("Agregaste un producto al carrito", "");
        } else {
          toast.show("Color no disponible", "");
        }
      } catch (error) {
        console.error('Error adding product:', error);
        toast.show("Error al agregar el producto", "");
      }
    },
    setHover(productIndex: number): void {
      this.isHover[productIndex] = true;
    },
    unsetHover(productIndex: number): void {
      this.isHover[productIndex] = false;
    },
    async fetchFeaturedPosts() {
      try {
        this.loading.posts = true;
        const response = await fetch('https://backend-test.tango.reprocard.com/notion/post?highlights=true');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        this.featuredPosts = data.results.map((post: any) => ({
          id: post.id,
          title: post.properties.Title.title[0]?.plain_text || '',
          subtitle: post.properties['Sub-title']?.rich_text[0]?.plain_text || '',
          author: post.properties.Autor?.rich_text[0]?.plain_text || 'Anónimo',
          date: post.properties['Fecha Publicación']?.date?.start || '',
          image: post.properties['Imagen 1']?.files[0]?.file.url || '/placeholder-image.jpg',
        }));
        this.loading.posts = false;
      } catch (error) {
        console.error('Error fetching featured posts:', error);
        this.loading.posts = false;
      }
    },
    formatDate(dateString: string): string {
      if (!dateString) return '';
      const date = new Date(dateString);
      const months = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'];
      return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
    },
    initializeSwipers() {
      // Banner Swiper - keep autoplay for this one
      new Swiper(this.$refs.banners as HTMLElement, {
        slidesPerView: 1,
        centeredSlides: true,
        loop: true,
        speed: 800,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        modules: [Navigation, Pagination],
      });

      // Featured Products Swiper - remove autoplay, add custom navigation
      const featuredProductsEl = this.$refs.featuredProducts as HTMLElement;
      if (featuredProductsEl) {
        this.featuredProductsSwiper = new Swiper(featuredProductsEl, {
          slidesPerView: 2,
          spaceBetween: 20,
          loop: true,
          // Remove autoplay
          breakpoints: {
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 4,
            },
          },
          modules: [Navigation],
        });

        // Add custom navigation
        const prevButton = document.querySelector('.custom-swiper-button-prev');
        const nextButton = document.querySelector('.custom-swiper-button-next');
        
        if (prevButton && nextButton && this.featuredProductsSwiper) {
          prevButton.addEventListener('click', () => {
            this.featuredProductsSwiper?.slidePrev();
          });
          
          nextButton.addEventListener('click', () => {
            this.featuredProductsSwiper?.slideNext();
          });
        }
      }
    },
  },
  beforeUnmount() {
    // Clean up Swiper instances to prevent memory leaks
    if (this.featuredProductsSwiper) {
      this.featuredProductsSwiper.destroy();
      this.featuredProductsSwiper = null;
    }
  }
});
